

export const homeObjOne = {
  id: 'Athlete NFTs',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Athlete NFTs',
  headline: 'Athlete NFTs Help Take Control of Their IP.',
  description:
    'Get access to exclusive content that allows all participants to receive perks and fan loyalty points. The future way to sponsor an athlete and content creators.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/Discover.jpg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjTwo = {
  id: 'discover',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Show your support',
  headline: 'Fans and Community Will Support Their Favorite Athletes.',
  description:
    'Our fractional NFTs economy allows for fan to support many athletes. The more you participate, the more loyalty points you get.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/AthleteFeeds.jpg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Tokenomics',
  headline: 'Tokenomics for our rewards system will be coming soon!',
  description:
    "All athletes will receive royalties on every transaction that is made with their token!",
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/svg-3.svg'),
  alt: 'Papers',
  dark: false,
  primary: false,
  darkText: true
};

export const homeObjFour = {
  id: 'rewards',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Fan Loyalty Points',
  headline: 'Loyalty Points can be Redeemed!',
  description:
    "Fans can redeem loyalty points for special NFT's on the platform or for our Governance Token CSS. Loyalty points cannot be purchased, only earned by engaging into the platform.",
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../images/NFT.jpg'),
  alt: 'Car',
  dark: true,
  primary: true,
  darkText: false
};

export const homeObjFive = {
  id: 'access',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Tiers Access',
  headline: 'The more you stake the higher level of Tier access.',
  description:
    'Fans with the higher tier will get the most exclusive perks and offers from the athletes they support. Perks include special NFT drops only for tier 1 and 2. Also may include exclusive discounts on sponsoring brands.',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/dashboard.jpg'),
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};

