import React from 'react';
import Icon1 from '../../images/svg-2.svg';
import Icon2 from '../../images/svg-3.svg';
import Icon3 from '../../images/svg-10.svg';
import Icon4 from '../../images/CSS2.png';
import Icon5 from '../../images/Redeem.png';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services'>
              <ServicesIcon src={Icon3} />
      <ServicesH1>Fans Can..</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Earn Loyalty Points</ServicesH2>
          <ServicesP>
            Earn points by unlocking any access tier level, staking, redeeming, and engaging in the platform.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Manage Portfolio</ServicesH2>
          <ServicesP>
          Redeem, buy, and sell NFTs and Athlete NFTs. Keep track of tier access perks. Manage tier access level.  
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} />
          {/* <ServicesIcon src={Icon4} /> */}
          <ServicesH2>Redeem Loyalty Points</ServicesH2>
          <ServicesP>
          Redeem points for special NFTs or redeem from our store. More value will be added to our points system later.
          </ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
